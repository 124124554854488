// Custom Vertical Menu

.slds-nav-vertical__item::before {
  content: none;
}

.slds-nav-vertical__item.slds-is-active::before,
.slds-nav-vertical__item:hover::before,
.slick-active a.slds-nav-vertical__item::before {
  content: none;
}

.slds-nav-vertical__item.slds-is-active .slds-nav-vertical__action,
.slick-active a.slds-nav-vertical__item .slds-nav-vertical__action,
.slds-nav-vertical__action:hover {
  box-shadow: none;
}

.slds-nav-vertical__item.slds-is-active::before,
.slds-nav-vertical__item:hover::before,
.slick-active a.slds-nav-vertical__item::before {
  background: none;
}

.slds-nav-vertical ul {
  padding: 0;
  margin: 0;
}

.slds-nav-vertical__action {
  color: $primary;
  cursor: pointer;

  &:focus {
    color: $primary-active;
  }

  &:hover {
    color: $primary-active;
  }
}
