.p-carousel {
  width: 100%;
}

.slick-prev,
.slick-next {
  color: inherit;
  position: absolute;

  &.slds-button:focus {
    box-shadow: none;
  }

  &::before {
    content: none;
  }
}

.slick-prev {
  .hide-previous & {
    display: none !important;
  }

  .hide-arrows-mobile & {
    @include breakpoint(0 $large) {
      display: none !important;
    }
  }
}

.slick-next {
  .hide-next & {
    display: none !important;
  }

  .hide-arrows-mobile & {
    @include breakpoint(0 $large) {
      display: none !important;
    }
  }
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.slick-dots {
  bottom: -2.5rem;

  .hide-indicators & {
    display: none !important;
  }
}

.slick-active a {
  @extend .slds-is-active;

  &.slds-carousel__indicator-action,
  &.slds-carousel__indicator-action:hover {
    background-color: $accessible;
    border-color: $accessible;
  }
}

.slds-carousel__indicators li {
  @extend .slds-carousel__indicator;

  a.slds-carousel__indicator-action {
    width: 0.7rem;
    height: 0.7rem;
  }
}

.glide__bullets {
  bottom: 0.5rem !important;
}