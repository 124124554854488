//
// Generic header styles:
// All arguments are optional. If not defined, the defaults below will be used
//
.slds-text-heading {
  color: $primary;
  font-weight: 700;
  font-family: $font-family-heading;
  //text-transform: uppercase;

  &.slds-current-color {
    color: inherit !important;
  }
}

.slds-text-heading--x-large,
.slds-text-heading_x-large {
  @extend .slds-text-heading--large;

  font-size: 2rem;
  font-family: $font-family-heading;
  font-weight: 700;
  line-height: 1.25;

  @include medium {
    font-size: 2.5rem;
  }

  @include large {
    font-size: 3rem;
    line-height: 1.05;
  }
}

.slds-text-heading--large,
.slds-text-heading_large {
  font-size: 1.625rem;
  font-family: $font-family-heading;
  font-weight: 700;
  line-height: 1.15;

  @include medium {
    font-size: 2rem;
    line-height: 1.25;
  }

  @include large {
    font-size: 2.25rem;
  }
}

.slds-text-heading--medium,
.slds-text-heading_medium {
  @extend .slds-text-heading--medium;

  font-size: 1.375rem;
  line-height: 1.14;

  @include medium {
    font-size: 1.5rem;
    line-height: 1.25; 
  }

  @include large {
    font-size: 1.75rem;
  }
}

.slds-text-heading--small,
.slds-text-heading_small {
  @extend .slds-text-heading--small;

  font-size: 1.125rem;
  line-height: 1.11;

  @include medium {
    line-height: 1.22;
  }
}

.slds-text-heading--x-small,
.slds-text-heading_x-small {
  @extend .slds-text-heading--small;

  font-size: 1rem;
  font-family: $font-family-heading;
  font-weight: 700;
  line-height: 1.05;

  @include medium {
    line-height: 1.11;
  }
}


.underline {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -7px;
    height: 1px;
    width: 5rem;
    border-bottom: 1px solid $brand-accessible;
  }
}

.slds-text-uppercase {
  text-transform: uppercase;
}

.slds-text-heading__link {
  text-decoration: none;
}

h1 {
  @extend .slds-text-heading;
  @extend .slds-text-heading--x-large;

  margin-bottom: $spacing-small;
  font-family: $font-family-heading;
  font-weight: 700;
}

h2 {
  @extend .slds-text-heading;
  @extend .slds-text-heading--large;

  margin-bottom: $spacing-small;
  font-family: $font-family-heading;
  font-weight: 700;
}

h3 {
  @extend .slds-text-heading;
  @extend .slds-text-heading--medium;

  margin-bottom: $spacing-small;
  font-family: $font-family-heading;
  font-weight: 700;
}

h4 {
  @extend .slds-text-heading--small;

  margin-bottom: $spacing-small;
  font-family: $font-family-heading;
  font-weight: 700;
}

h5 {
  @extend .slds-text-heading--x-small;

  margin-bottom: $spacing-small;
  font-family: $font-family-heading;
  font-weight: 700;
}

// TODO: BEM selectors should be applied to wysiwyg-created content

.text-long {
  h1 {
    @extend .slds-text-heading;
    @extend .slds-text-heading--x-large;

    margin-bottom: $spacing-small;
    font-family: $font-family-heading;
    font-weight: 700;
  }

  h2 {
    @extend .slds-text-heading;
    @extend .slds-text-heading--large;

    margin-bottom: $spacing-small;
    font-family: $font-family-heading;
    font-weight: 700;
  }

  h3 {
    @extend .slds-text-heading;
    @extend .slds-text-heading--medium;

    margin-bottom: $spacing-small;
    font-family: $font-family-heading;
    font-weight: 700;
  }

  h4 {
    @extend .slds-text-heading--small;

    margin-bottom: $spacing-small;
    font-family: $font-family-heading;
    font-weight: 700;
  }

  h5 {
    @extend .slds-text-heading--x-small;

    margin-bottom: $spacing-small;
    font-family: $font-family-heading;
    font-weight: 700;
  }
}
