.slds-tabs_default__nav {
  @include list-reset();

  .slds-tabs_default__item {
    @extend .slds-button;
    @extend .slds-button--outline-brand-primary;

    text-transform: none;
    background: $gray-3;
    border: 1px solid $gray-7;
    border-radius: 0.25rem 0.25rem 0 0;
    border-bottom: 0;

    &.slds-is-active {
      @extend .slds-button--brand-primary;
    }
  }
}

.slds-tabs_scoped-nav {
  @extend .slds-tabs_scoped__nav;
}

.slds-tabs_scoped-nav,
.slds-tabs_scoped__nav {
  @include list-reset();

  .slds-tabs_scoped__item {
    text-align: center;

    // .slds-tabs_scoped__link {
    //   background-color: $gray-1;
    //   border-bottom: 1px solid transparent;
    //   border-color: #dddbda;
    // }

    &::after {
      display: block;
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      height: 0;
      position: absolute;
    }

    &.slds-is-active {
      // .slds-tabs_scoped__link {
      //   background-color: $gray-2;
      // }

      &::after {
        background-color: $accessible;
        height: 0.1875rem;
      }
    }
  }
}
