// Videos
.slds-video {
  @extend .slds-image;

  &:hover::after {
    content: none !important;
  }

  &__crop {
    @extend .slds-image__crop;

    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &_1-by-1,
    &--1-by-1 {
      padding-top: 100%;
    }

    &_16-by-9,
    &--16-by-9 {
      padding-top: 56.25%;
    }

    &_4-by-3,
    &--4-by-3 {
      padding-top: 75%;
    }
  }

  &--crop {
    @extend .slds-video__crop;

    &_1-by-1,
    &--1-by-1 {
      padding-top: 100%;
    }

    &_16-by-9,
    &--16-by-9 {
      padding-top: 56.25%;
    }

    &_4-by-3,
    &--4-by-3 {
      padding-top: 75%;
    }
  }
}
