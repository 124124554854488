$color-pl-place-holder: $gray-5;

.pl-place-holder {
  color: $color-pl-place-holder;
  border: 4px dashed $color-pl-place-holder;
  padding: 20px;

  &__content {
    font-size: 3.2rem;
    line-height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--banner {
      min-height: 15vw;
      background-image: url('?holder.js/1920x400?bg=2a2025&text=%20');
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;
    }

    &--hero {
      min-height: 45vw;
      background-image: url('?holder.js/1920x400?bg=2a2025&text=%20');
      background-size: cover !important;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
