#split-nav-container {
  border-bottom: none;
  height: auto;
  width: 100%;
  box-shadow: 0 6px 7px rgba(0, 0, 0, 0.04);
  z-index: 2;
  position: relative;

  ul {
    @include list-reset();
  }

  .slds-context-bar__secondary {
    height: 2.5rem;

    .slds-grid_align-end .slds-context-bar__item:last-child {
      border-right: none;

      .scrolled & {
        border-right: 2px solid $gray-3;
      }
    }

    .slds-grid_align-end .slds-context-bar__item:first-child {
      border-left: 2px solid $gray-3;
    }

    .slds-context-bar__item {
      cursor: auto;
      border-left: 2px solid $gray-3;
      position: static;

      @extend .slds-p-horizontal--large;

      &:first-child {
        border-left: none;

        .scrolled & {
          border-left: 2px solid $gray-3;
        }
      }

      &:last-child {
        border-right: 2px solid $gray-3;
      }

      .slds-context-bar__label-action {
        text-transform: uppercase;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      a {
        padding: 0.5rem 0;
        text-transform: uppercase;
        font-weight: 700;
        color: $primary;
        text-decoration: none;

        &:active(.no-link),
        &:hover:not(.no-link) {
          color: inherit;
          color: $primary-active;
        }
      }

      &.slds-is-active::before {
        content: none;
      }

      &:hover::after {
        content: none !important;
      }

      &::before {
        content: none !important;
      }

      .slds-dropdown-trigger .slds-dropdown {
        top: 0;
      }

      .slds-dropdown {
        position: relative;
        border: none;
        background: none;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
      }

      & > .slds-dropdown {
        z-index: -1;
        position: absolute;
        width: 100vw;
        max-width: none;
        background: $main-menu-background url('/themes/custom/mywmu/images/menu-background.png') no-repeat center center;
        background-size: cover;
        margin-top: 0;
        //box-shadow: inset 0 3px 6px -3px rgba(0, 0, 0, 0.21);
        font-size: 1rem;
        padding: 3rem 0;
        border: none;
        color: $gray-1;
        border-radius: 0;

        &::before {
          content: none !important;
        }

        @include transition(all, 0.25s, ease-in-out);

        & > .slds-container--x-large > ul {
          @include list-reset();

          width: 100%;

          &.slds-p-horizontal--large {
            padding: 0 3rem;
          }

          & > li {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            width: 25%;

            & > a {
              text-transform: uppercase;
              font-family: $font-family-heading;
              font-weight: 700;
              font-size: 1.1rem;
              line-height: 1.5rem;
              padding: 0 0.75rem;
              color: $gray-1;

              &:hover(.no-link) {
                color: $accessible;
              }
            }

            & > .slds-dropdown {
              top: 0;
              float: none;
            }
          }
        }

        a {
          padding: 0 0.75rem;
          font-family: $font-family-text;
          text-transform: none;
          font-weight: 400;
          color: $gray-1;

          &:hover {
            color: $accessible;
            background: transparent;
          }

          &.slds-text-title_caps {
            text-transform: uppercase;
            font-size: inherit;
            font-weight: inherit;
          }
        }
      }
    }
  }

  .branding {
    position: relative;

    & > .slds-grid {
      position: relative;
      bottom: 3rem;
      transition: all 0.5s ease;

      .scrolled & {
        bottom: -0.35rem;
        transition: all 0.5s ease;
      }
    }
  }
}

[data-theme="athletics"] {
  #split-nav-container {
    .slds-context-bar__secondary {
      .slds-context-bar__item {
        position: relative;

        & > .slds-dropdown {
          width: auto;
          border-bottom-left-radius: .5rem;
          border-bottom-right-radius: .5rem;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          background-size: auto;

          & > .slds-container--x-large > ul {
            li {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#molecules-split-menu .sg-pattern-example {
  margin-top: 4rem;
  z-index: 5;
}
