/// Mixin - list-reset
/// Reset list item defaults when no margin, padding, list styles needed
@mixin list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-item {
  margin-bottom: 0.2em;
  padding-left: 0.2em;
}

// TODO: BEM selectors should be applied to wysiwyg-created content

.text-long {
  ol,
  ul {
    li {
      @extend .list-item;
    }
  }

  ul {
    @extend .slds-list_dotted;
  }

  ol {
    @extend .slds-list_ordered;
  }
}

.slds-is-nested {
  margin-left: 1rem !important;
}