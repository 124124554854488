// Custom tile

.slds-tile {
  position: relative;
  background-size: cover;
  background-position: left center;
  background-repeat: no-repeat;
  width: 100%;

  & .slds-tile__title {
    position: absolute;
    bottom: 0;
    right: 0;
    background: white;
  }

  & .slds-tile__flags {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    color: $gray-1;
  }

  & .slds-tile__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    color: $gray-1;

    & > span {
      display: flex;
      align-items: center;
    }
  }

  &:hover .slds-tile__hover {
    visibility: visible;
  }

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}
