.text-with-background-image {
  &.Cover {
    height: 45vmax;

    .slds-grid {
      height: 45vmax;
    }
  }

  &.Banner {
    min-height: 20vmax;

    .slds-grid {
      min-height: 20vmax;
    }
  }

  &.Banner-Large {
    min-height: 33vmax;

    .slds-grid {
      min-height: 33vmax;
    }
  }

  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  .slds-text-heading {
    color: inherit;
  }

  .slds-grid {
    padding-top: 12rem;
    padding-bottom: 3rem;
    margin: 0 auto;

    @media (max-width: $mq-medium) {
      padding-top: 6rem;
    }
  }
}
