// Accordion Item

.slds-accordion {
  @include list-reset();

  padding: 0 15px;

  p {
    margin-left: 1.75rem;
  }
}

.slds-accordion__content {
  @include transition(all, 0.5s, ease-in-out);
}

.slds-accordion__section {
  padding: 1rem;
}
