/////////////////////
// General Mixins (put specific ones in component files where applicable)

/// Mixin - Clearfix.
/// Adds clearfix based on http://bourbon.io/docs/#clearfix
/// use example =   @include cleafix

@mixin clearfix {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
}

/// Mixin - Wrapper
/// Outer container mixin for large screens
@mixin wrapper($container-max-width: $max-width, $outer-container-break: $small, $v-margin: 0, $v-padding: 0, $h-padding: $space, $h-padding-large: $h-padding) {
  max-width: #{$container-max-width};
  width: 100%;
  margin: #{$v-margin} auto;
  padding: #{$v-padding} #{$h-padding};

  @include breakpoint($outer-container-break) {
    padding: #{$v-padding} #{$h-padding-large};
  }

  @include breakpoint($container-max-width) {
    padding-left: calc(#{$h-padding-large} + calc(-50vw + calc(#{$container-max-width} / 2)));
    padding-right: calc(#{$h-padding-large} + calc(-50vw + calc(#{$container-max-width} / 2)));
  }
}

/// Use the breakout mixin for elements that should be edge-to-edge
/// Even when a parent container uses the wrapper mixin
@mixin breakout($h-padding: $space) {
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

/// Mixin - Standard Margin
@mixin margin {
  margin-bottom: 1em;
}

@mixin no-bottom {
  margin-bottom: 0;
}
