/* Brand Colors */
$primary: var(--wmudar-primary, #532e1f); //Primary
$primary-lighten-5p: var(--wmudar-primary-lighten-5p, #613524);
$primary-active: var(--wmudar-primary-active, #6f3d29); //Primary Active
$primary-active-lighten-5p: var(--wmudar-primary-active-lighten-5p, #7c442d);
$primary-off: var(--wmudar-primary-off, #6E5645);
$light: var(--wmudar-light, #e8d3a9); //Light
$light-lighten-5p: var(--wmudar-light-lighten-5p, #e9d5ad);
$light-active: var(--wmudar-light-active, #ceb279); //Light Active
$light-active-lighten-5p: var(--wmudar-light-active-lighten-5p, #d0b57f);
$dark: var(--wmudar-dark, #21110a); //Dark
$dark-lighten-5p: var(--wmudar-dark-lighten-5p, #321a0f);
$dark-active: var(--wmudar-dark-active, #331a0f); //Dark Active
$dark-active-lighten-5p: var(--wmudar-dark-active-lighten-5p, #442214);
$accessible: var(--wmudar-accessible, #f1c500); //Accessible
$accessible-lighten-5p: var(--wmudar-accessible-lighten-5p, #fecf00);
$accessible-active: var(--wmudar-accessible-active, #9e5900); //Accessible Active
$accessible-active-lighten-5p: var(--wmudar-accessible-active-lighten-5p, #af6200);
$contrast: var(--wmudar-contrast, #a38741); //Contrast
$contrast-lighten-5p: var(--wmudar-contrast-lighten-5p, #ad8f45);
$contrast-active: var(--wmudar-contrast-active, #725f2d); //Contrast Active
$contrast-active-lighten-5p: var(--wmudar-contrast-active-lighten-5p, #7e6931);

/* Neutral Colors */
$gray-1: var(--wmudar-gray-1, #fff); //Gray 1
$gray-2: var(--wmudar-gray-2, #fafaf9); //Gray 2
$gray-3: var(--wmudar-gray-3, #f3f2f2); //Gray 3
$gray-4: var(--wmudar-gray-4, #ecebea); //Gray 4
$gray-5: var(--wmudar-gray-5, #dddbda); //Gray 5
$gray-6: var(--wmudar-gray-6, #c9c7c5); //Gray 6
$gray-7: var(--wmudar-gray-7, #b0adab); //Gray 7
$gray-8: var(--wmudar-gray-8, #969492); //Gray 8
$gray-9: var(--wmudar-gray-9, #706e6b); //Gray 9
$gray-10: var(--wmudar-gray-10, #514f4d); //Gray 10
$gray-11: var(--wmudar-gray-11, #3e3e3c); //Gray 11
$gray-12: var(--wmudar-gray-12, #2b2826); //Gray 12

/* Neutral Off */
$offgray-6: var(--wmudar-offgray-6, #bcb1a9);
$offgray-8: var(--wmudar-offgray-8, #8f7d71);
$offgray-9: var(--wmudar-offgray-9, #6e5645);

/* System Colors */
$success: var(--wmudar-success, #4bca81); //Success
$warning: var(--wmudar-warning, #ffb75d); //Warning
$destructive: var(--wmudar-destructive, #c23934); //Destructive
$error: var(--wmudar-error, #d4504c); //Error
$main-menu-background: var(--wmudar-main-menu-background, $primary); // Main Menu Dropdown Background Tint

:root, [data-theme="default"] {
    --wmudar-primary-off: #6E5645;
    --wmudar-primary: #532e1f; //Primary
    --wmudar-primary-active: #6f3d29; //Primary Active
    --wmudar-light: #e8d3a9; //Light
    --wmudar-light-active: #ceb279; //Light Active
    --wmudar-dark: #21110a; //Dark
    --wmudar-dark-active: #331a0f; //Dark Active
    --wmudar-accessible: #f1c500; //Accessible
    --wmudar-accessible-active: #9e5900; //Accessible Active
    --wmudar-contrast: #a38741; //Contrast
    --wmudar-contrast-active: #725f2d; //Contrast Active
    
    --wmudar-gray-1: #fff; //Gray 1
    --wmudar-gray-2: #fafaf9; //Gray 2
    --wmudar-gray-3: #f3f2f2; //Gray 3
    --wmudar-gray-4: #ecebea; //Gray 4
    --wmudar-gray-5: #dddbda; //Gray 5
    --wmudar-gray-6: #c9c7c5; //Gray 6
    --wmudar-gray-7: #b0adab; //Gray 7
    --wmudar-gray-8: #969492; //Gray 8
    --wmudar-gray-9: #706e6b; //Gray 9
    --wmudar-gray-10: #514f4d; //Gray 10
    --wmudar-gray-11: #3e3e3c; //Gray 11
    --wmudar-gray-12: #2b2826; //Gray 12

    --wmudar-offgray-6: #bcb1a9;
    --wmudar-offgray-8: #8f7d71;
    --wmudar-offgray-9: #6e5645;

    --wmudar-success: #4bca81; //Success
    --wmudar-warning: #ffb75d; //Warning
    --wmudar-destructive: #c23934; //Destructive
    --wmudar-error: #d4504c; //Error
}


[data-theme="athletics"] {
    // --wmudar-accessible: #ceb07c; //Accessible
    // --wmudar-accessible-lighten-5p: #d0b382;
    // --wmudar-accessible-active: #c5a163; //Accessible Active
    // --wmudar-accessible-active-lighten-5p: #c7a56a;
    --wmudar-main-menu-background: #665242;
}