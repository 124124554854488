@import 'variables';

/* Overrides for SLDS */
$brand-primary-transparent-10: rgba(78,42,25, 0.1);
$gray-1-transparent-75: rgba(255,255,255, 0.75);
$gray-6-transparent-75: rgba(201,199,197, 0.75);

$brand-primary: $primary;
$brand-primary-active: $primary-active;
$brand-light: $light;
$brand-light-active: $light-active;
$brand-dark: $dark;
$brand-dark-active: $dark-active;
$brand-accessible: $accessible;
$brand-accessible-active: $accessible-active;
$brand-contrast: $contrast;
$brand-contrast-active: $contrast-active;

$text-color: $gray-11;
$detail-text-color: $gray-9;
$action-color: $primary;
$action-color-active: $primary-active;
$link-color: $primary;
$link-color-active: $primary-active;
$overlay-text-color: $gray-1;
$border-color: $gray-5;
$border-color-active: $primary-active;

$color-border: $gray-8;
$color-link: $gray-11;
$color-link--hover: $gray-8;
$color-heading: $gray-11;

$color-text-default: $text-color;

$color-text-action-label: $detail-text-color;
$color-text-label: $detail-text-color;
$color-text-placeholder: $detail-text-color;
$color-text-weak: $detail-text-color;

$color-background-button-brand: $action-color;
$color-border-brand: $action-color;
$color-border-button-brand: $action-color;
$color-brand: $action-color;
$color-text-brand: $action-color;
$color-text-tab-label-selected: $action-color;
$color-text-action-label-active: $action-color;

$color-text-link: $link-color;
$color-text-link-active: $link-color-active;
$color-text-link-hover: $link-color-active;
$color-text-link-focus: $link-color-active;
$brand-text-link: $link-color;
$brand-text-link-active: $link-color-active;
$brand-text-link-hover: $link-color-active;
$brand-text-link-focus: $link-color-active;

$color-text-button-brand: $overlay-text-color;
$color-text-button-brand-hover: $overlay-text-color;
$color-text-inverse: $overlay-text-color;

$color-border: $border-color;
$color-border-button-default: $border-color;
$color-border-input: $border-color;
$color-border-separator-alt: $border-color;

$color-border-input-active: $border-color-active;
$shadow-button-focus: 0 0 3px $border-color-active;

.slds-theme_shade-gradient-transparent {
  background-image: linear-gradient(to bottom, rgba(43,40,38, 0) 0%, rgba(43,40,38, 1));

  @include large {
    background-image: linear-gradient(to bottom, rgba(43,40,38, 0) 60%, rgba(43,40,38, 1));
  }
}

.slds-theme_shade-transparent {
  background-color: rgba(62,62,60, 0.6);
}

.slds-theme_primary {
  background-color: $primary;
  border-color: $primary;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_primary_shaded {
    color: $primary-lighten-5p;
  }

  &.slds-theme_primary-transparent {
    background-color: rgba(78,42,25, 60%);
  }
}

.slds-theme_primary-active {
  background-color: $primary-active;
  border-color: $primary-active;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_primary-active_shaded {
    color: $primary-active-lighten-5p;
  }
}

.slds-theme_light {
  background-color: $light;
  border-color: $light;

  &.slds-theme_light_shaded {
    color: $light-lighten-5p;
  }
}

.slds-theme_light-active {
  background-color: $light-active;
  border-color: $light-active;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_light-active_shaded {
    color: $light-active-lighten-5p;
  }
}

.slds-theme_dark {
  background-color: $dark;
  border-color: $dark;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_dark_shaded {
    color: $dark-lighten-5p;
  }
}

.slds-theme_dark-active {
  background-color: $dark-active;
  border-color: $dark-active;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_dark-active_shaded {
    color: $dark-active-lighten-5p;
  }
}

.slds-theme_accessible {
  background-color: $accessible;
  border-color: $accessible;
  color: $gray-10;

  .slds-text-heading {
    color: $gray-10;
  }

  a {
    color: $primary !important;

    &:hover {
      color: $primary-active !important;
    }
  }

  &.slds-theme_accessible_shaded {
    color: $accessible-lighten-5p;
  }
}

.slds-theme_accessible-active {
  background-color: $accessible-active;
  border-color: $accessible-active;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_accessible-active_shaded {
    color: $accessible-active-lighten-5p;
  }
}

.slds-theme_contrast {
  background-color: $contrast;
  border-color: $contrast;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_contrast_shaded {
    color: $contrast-lighten-5p;
  }
}

.slds-theme_contrast-active {
  background-color: $contrast-active;
  border-color: $contrast-active;
  color: $gray-1;

  .slds-text-heading {
    color: $gray-1;
  }

  &.slds-theme_contrast-active_shaded {
    color: $contrast-active-lighten-5p;
  }
}
