// Path Indicator
ul.slds-path__nav {
  margin-left: 0;
  padding-left: 0;
  list-style: none;
}

.slds-path__nav {
  .slds-path__item {

    &:last-of-type {
      background: none;
    }

    &.slds-is-incomplete {
      .slds-path__link {
        color: $offgray-8;
      }

      .slds-theme_primary & {
        .slds-path__link {
          color: $offgray-6;
        }

        background: $offgray-9;

        &::before,
        &::after {
          background: $offgray-9;
        }

        &:last-of-type {
          background: none;
        }
      }
    }

    &.slds-is-complete {
      background: $primary;

      &::before,
      &::after {
        background: $primary;
      }

      .slds-theme_primary & {
        .slds-path__link {
          color: $primary;
        }

        background: $gray-1;

        &::before,
        &::after {
          background: $gray-1;
        }
      }

      &:last-of-type {
        background: none;
      }
    }

    &.slds-is-current {
      .slds-path__link {
        color: $primary;
      }

      &::before,
      &::after {
        background-image: linear-gradient(90deg, $primary, $primary 0.125rem, transparent 0.125rem), linear-gradient(90deg, $primary, $primary 0.125rem, transparent 0.125rem), linear-gradient($primary, $primary 0.125rem, transparent 0.125rem);
        
        .slds-theme_primary & {
          background-image: none;
        }
      }

      &:first-child {
        border: 2px solid $primary;
        border-right: 0;

        .slds-theme_primary & {
          border: 2px solid $gray-1;
          border-right: 0;
        }

        &::before,
        &::after {
          background-color: #fff;
          background-image: linear-gradient(90deg, $primary, $primary 0.125rem, transparent 0), linear-gradient(90deg, transparent, transparent 0.125rem, transparent 0), linear-gradient($primary, $primary 0.125rem, transparent 0);
        
          .slds-theme_primary & {
            background-image: none;
          }
        }
      }

      &:last-of-type {
        background: none;
      }
    }

    &.slds-is-active {
      background: $accessible !important;

      .slds-path__link {
        color: $gray-1 !important;
      }

      &::before,
      &::after {
        background: $accessible;
      }

      &:first-child {
        border: 0 !important;

        &::before,
        &::after {
          background-color: $accessible;
          background-image: none;
        }
      }

      &:last-of-type {
        background: none;
      }
    }
  }
}
