//
.branding {
  font-size: 1rem;
  font-weight: 900;
  z-index: 1;

  .slds-col.slds-align_absolute-center {
    flex-grow: 0;
  }

  // .branding__site-logo {
  //   img {
  //     max-height: 5.25rem;
  //     transition: all 0.5s ease;

  //     .scrolled & {
  //       max-height: 2rem;
  //       transition: all 0.5s ease;
  //     }
  //   }
  // }

  &.branding--footer .branding__site-logo {
    img {
      -webkit-filter: none;
      filter: none;

      &.show-big {
        .scrolled & {
          display: block;
          max-height: 9rem;
        }
      }

      &.show-small {
        .scrolled & {
          display: none;
        }
      }
    }
  }

  .branding__site-logo {
    img {
      -webkit-filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.04));
      filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.04));
      max-height: 4rem;

      &.show-big {
        .scrolled & {
          display: none;
        }
      }

      &.show-small {
        display: none;
        padding: 0 1rem;

        .scrolled & {
          display: block;
        }
      }

      .scrolled & {
        max-height: 2rem;
        -webkit-filter: none;
        filter: none;
      }
    }
  }

  .givingday & .branding__site-logo {
    img {
      -webkit-filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.04));
      filter: drop-shadow(0 6px 7px rgba(0, 0, 0, 0.04));
      max-height: 11rem;

      &.show-big {
        .scrolled & {
          display: block;
        }
      }

      &.show-small {
        .scrolled & {
          display: none;
        }
      }

      .scrolled & {
        max-height: 2rem;
        -webkit-filter: none;
        filter: none;
      }
    }
  }

  .branding__site-slogan {
    color: $accessible;
    cursor: pointer;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  $size: 4rem;
}

[data-theme="athletics"] {
  .branding {
    .branding__site-logo {
      img {
        max-height: 8rem;
      }
    }
  }
}

.branding-small {
  font-size: 1.5rem;
  font-weight: 900;

  .branding__site-logo {
    img {
      max-height: 2rem;
    }
  }

  .branding__site-slogan {
    color: $primary;
    cursor: pointer;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }
}

.branding__site-name {
  color: $primary;

  &:focus,
  &:hover {
    text-decoration: none;
    color: $primary-active;
  }
}

#molecules-branding {
  .branding,
  .branding-small {
    display: block !important;
  }
}
