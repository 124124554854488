// These styles are specifically for Pattern Lab.
// They are not used by the site in any way.

.pl-template {
  display: flex;
  flex-flow: column nowrap;
}

.pl,
.sg-main {
  .sg-subtype h2 {
    font-size: 3rem;
    text-transform: uppercase;
    background-color: $primary;

    a {
      @include wrapper(
        $v-padding: $space
      );

      display: block;
      color: $accessible;
      text-decoration: none;
      line-height: 1;
    }
  }

  .sg-pattern > div {
    @include wrapper;
  }

  .sg-pattern-title {
    color: $primary-active;
    font-size: 1.4rem !important;
    text-transform: none !important;

    a {
      color: $primary !important;
      font-family: Calibre;
      font-size: 2.2rem;
      font-weight: 500;
      text-transform: uppercase !important;
    }
  }

  .sg-pattern-extra {
    margin-bottom: 1rem !important;
  }

  .sg-pattern-example {
    position: relative;
  }

  .sg-pattern-extra-info {
    width: 100%;
  }

  .sg-pattern-extra-code {
    display: none;
  }

  .visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }

  .sg-colors {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      flex: 0 0 100px;
      margin: 0 0.5rem 1rem;
    }

    &__sample {
      display: block;
      height: 100px;
      margin-bottom: 0.3em;
    }
  }
}
