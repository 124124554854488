.banner {
  color: #fff !important;

  .slick-list {
    width: 100%;

    .slick-track {
      width: 100%;
    }
  }

  .slick-next {
    right: 2rem;
    width: 2rem;
    height: 2rem;
    z-index: 10;
  }

  .slick-prev {
    left: 2rem;
    width: 2rem;
    height: 2rem;
    z-index: 10;
  }

  .slick-dots {
    bottom: 0.5rem;
    z-index: 10;
  }
}
