// Custom Images
.image-responsive {
  img {
    width: 100%;
  }
}

.slds-image {
  &:hover {
    border: none;
    box-shadow: none;
    transition: none;

    &::before,
    &::after {
      content: none !important;
    }
  }
}

.text-long .embedded-entity {
  margin: 1rem;
}

figure {
  .tag {
    position: absolute;
    top: 5%;
    left: -5px;
    box-shadow: 0 6px 7px rgba(0,0,0,.15);
    z-index: 1;
    color: $primary;
    font-weight: bold;
    text-transform: uppercase;

    span {
      background: white;
      padding: 0.25rem 0.5rem;
    }

    &::after {
      transform: rotate(45deg);
      position: absolute;
      content: '';
      display: block;
      z-index: -1;
      bottom: -0.35rem;
      left: 0.1rem;
      border-width: 0.25rem;
      border-color: transparent transparent $gray-6 transparent;
      border-style: solid;
    }
  }

  &.caption-overlay {
    figcaption {
      background: $gray-1-transparent-75;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}
