.alert {
  padding: 3.5rem 0 1rem;
  width: 100%;
  z-index: 1;

  &.init {
    display: none;
  }

  &.dismissed {
    display: none;
  }

  .toggle-dismiss {
    cursor: pointer;
    margin-right: 0.25rem;
  }

  @include large {
    padding: 8.5rem 0 1rem;
  }
}
  