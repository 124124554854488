//
// General Layout
//
html {
  overflow-x: hidden;
  background: $gray-1;

  &.mobile-menu--open {
    overflow-y: hidden;

    body {
      overflow-y: hidden;
    }
  }
}

body {
  background: $gray-1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
}

#ServiceCommunityTemplate {
  flex: 1;
  margin-bottom: 4rem;

  .cCenterPanel {
    max-width: 980px;
    width: 100%;
  }
}

.layout-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

$sidebar-width: 25%;
$gutter: $space;
$main-width: calc(100% - (#{$sidebar-width} + #{$gutter}));

.main {
  @include wrapper;

  margin-bottom: 4rem;
  flex: 1;
  align-items: flex-start;
  align-content: flex-start;
}

//
// Layout Using Flexbox (IE11+).
// Remove this and uncomment Grid code below to use CSS Grid.
//

.main {
  @include large {
    display: flex;
    flex-wrap: wrap;
  }
}

.main-system {
  flex: 1;
  width: 100%;
}

.main-sidebar {
  margin-top: 58px;
  margin-bottom: $space-double;
  order: 2;

  @include large {
    width: $sidebar-width;
    margin-bottom: 0;
  }
}

.main-content {
  width: 100%;

  @include large {
    &--with-sidebar {
      margin-right: $gutter;
      width: $main-width;
    }
  }
}

.scrolled .slds-hide_scrolled {
  display: none;
}

.breakout {
  @include breakout;
}

.visually-hidden {
  display: none;
}

//
// Layout Using CSS Grid (NO IE11 support)
// Remove Flexbox code above and uncomment this section to use CSS Grid.
//

// .main {
//   &--with-sidebar {
//     @include large {
//       display: grid;
//       grid-gap: $gutter;
//       grid-template-areas: "content sidebar";
//       grid-template-columns: $main-width #{$sidebar-width};
//     }
//   }
// }
//
// .main-sidebar {
//   grid-area: sidebar;
//   margin-bottom: $space-double;
// }
//
// .main-content {
//   grid-area: content;
//   width: 100%;
// }

.slds-m-bottom_xxx-large {
  margin-bottom: 4rem;
}

.slds-display_block {
  display: block !important;
}

.slds-h-100 {
  height: 100%;
}

.taxonomy div.more-link {
  position: absolute;
  bottom: 0;
  left: 0;
  background: none;
  padding: 0;
  border: 0;
}

.taxonomy {
  padding-bottom: 2.5rem;
}