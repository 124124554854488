// CSS-only select styling (from https://github.com/filamentgroup/select-css)

.slds-form-element__dropdown {
  border: 1px solid $gray-4;
  display: block;
  position: relative;

  &::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 9px solid $gray-11;
    content: " ";
    position: absolute;
    top: 42%;
    right: 1em;
    z-index: 2;

    /* These hacks make the select behind the arrow clickable in some browsers */
    pointer-events: none;
    display: none;
  }

  &:hover {
    border-color: $gray-12;
  }
}

.slds-form-element__select {
  border: 1px solid $gray-4;
  height: 41px; // set height required for discrepancy between .slds-form-element__dropdown border and the select :focus border
  font-size: 16px;
  margin: 0;
  outline: none;
  padding: 0.6em 0.8em 0.5em;
  width: 100%;

  :focus {
    outline: none;
    color: #222;
  }
}

@supports ( -webkit-appearance: none ) or ( appearance: none ) or ( ( -moz-appearance: none ) and ( mask-type: alpha ) ) {
  /* Show custom arrow */
  .slds-form-element__dropdown::after {
    display: block;
  }

  /* Remove select styling */
  .slds-form-element__select {
    padding-right: 2em; /* Match-01 */

    /* inside @supports so that iOS <= 8 display the native arrow */
    background: none; /* Match-04 */

    /* inside @supports so that Android <= 4.3 display the native arrow */
    border: 1px solid transparent; /* Match-05 */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border-color: $gray-9;
      border-radius: 0;
    }
  }
}
