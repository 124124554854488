//
.search-block-form {
  &.contextual-region:hover .autohide-search-form {
    padding-right: 2rem;

    .mobile-menu & {
      .slds-form-element.slide-in {
        width: 10rem;
      }
    }
  }

  .mobile-menu & form {
    display: flex;
    justify-content: right;
  }

  .slds-button--icon {
    line-height: 1.875rem;
  }
}

.autohide-search-form {
  .slds-form-element {
    overflow: hidden;
    right: 0;
    width: 0;
    margin-right: 0;

    @include transition(all, 0.5s, ease);

    &.slide-in {
      width: 12rem;

      @include transition(all, 0.5s, ease);
    }

    .slds-form-element__control {
      width: 100%;
    }
  }
}
