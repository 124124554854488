//

.slds-form--inline,
.slds-form_inline {
  .form-wrapper {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 0;
  }
}

.slds-checkbutton__label {
  font-family: $font-family-heading;
  font-weight: 700;
  color: $primary;

  .slds-checkbutton__label:hover {
    color: $primary-active;
  }
}

.slds-radio_button__label {
  @extend .slds-button--outline-brand-primary;

  .slds-radio_faux {
    padding-left: 0;
    padding-right: 0;
  }
}

.slds-radio_button [type="radio"]:checked + .slds-radio_button__label {
  @extend .slds-button--brand-primary;
}

.slds-input,
.slds-select {
  color: $gray-11;

  &::placeholder {
    color: $gray-5;
  }
}

.slds-form-element__row {
  margin-bottom: 0;
}

.slds-form-element_compound .slds-form-element {
  margin-bottom: 0.5rem;
}

.slds-checkbox--add-button [type=checkbox]:checked + .slds-checkbox--faux,
.slds-checkbox--add-button [type=checkbox]:checked + .slds-checkbox_faux,
.slds-checkbox--add-button [type=checkbox]:checked ~ .slds-checkbox--faux,
.slds-checkbox--add-button [type=checkbox]:checked ~ .slds-checkbox_faux,
.slds-checkbox_add-button [type=checkbox]:checked + .slds-checkbox--faux,
.slds-checkbox_add-button [type=checkbox]:checked + .slds-checkbox_faux,
.slds-checkbox_add-button [type=checkbox]:checked ~ .slds-checkbox--faux,
.slds-checkbox_add-button [type=checkbox]:checked ~ .slds-checkbox_faux {
  background: $accessible;
}

.slds-checkbox--add-button .slds-checkbox--faux::after,
.slds-checkbox--add-button .slds-checkbox--faux::before,
.slds-checkbox--add-button .slds-checkbox_faux::after,
.slds-checkbox--add-button .slds-checkbox_faux::before,
.slds-checkbox_add-button .slds-checkbox--faux::after,
.slds-checkbox_add-button .slds-checkbox--faux::before,
.slds-checkbox_add-button .slds-checkbox_faux::after,
.slds-checkbox_add-button .slds-checkbox_faux::before {
  background: $gray-7;
}

.slds-checkbox--add-button .slds-checkbox--faux,
.slds-checkbox--add-button .slds-checkbox_faux,
.slds-checkbox_add-button .slds-checkbox--faux,
.slds-checkbox_add-button .slds-checkbox_faux {
  width: 1.5rem;
  height: 1.5rem;
}

.slds-checkbox--toggle [type=checkbox]:checked + .slds-checkbox--faux,
.slds-checkbox--toggle [type=checkbox]:checked + .slds-checkbox--faux_container .slds-checkbox--faux,
.slds-checkbox--toggle [type=checkbox]:checked + .slds-checkbox_faux,
.slds-checkbox--toggle [type=checkbox]:checked + .slds-checkbox_faux_container .slds-checkbox_faux,
.slds-checkbox--toggle [type=checkbox]:checked ~ .slds-checkbox--faux,
.slds-checkbox--toggle [type=checkbox]:checked ~ .slds-checkbox_faux,
.slds-checkbox_toggle [type=checkbox]:checked + .slds-checkbox--faux,
.slds-checkbox_toggle [type=checkbox]:checked + .slds-checkbox--faux_container .slds-checkbox--faux,
.slds-checkbox_toggle [type=checkbox]:checked + .slds-checkbox_faux,
.slds-checkbox_toggle [type=checkbox]:checked + .slds-checkbox_faux_container .slds-checkbox_faux,
.slds-checkbox_toggle [type=checkbox]:checked ~ .slds-checkbox--faux,
.slds-checkbox_toggle [type=checkbox]:checked ~ .slds-checkbox_faux {
  border-color: $primary;
  background-color: $primary;
}
