//
.split-mobile-menu {
  position: fixed;
  margin-top: 2.5rem;
  top: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: 0.5s;
  z-index: 10;
  background-color: $gray-1;
  transform: translateX(100%);
  max-height: 100vh;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.21);

  ul {
    @include list-reset();
  }

  &.split-mobile-menu--open {
    transform: translateX(0);
  }

  .toolbar-fixed &,
  .toolbar-vertical & {
    margin-top: calc(39px + 2.5rem);
  }

  .slds-list_horizontal {
    justify-content: center;

    .slds-list_horizontal__item {
      .slds-list_horizontal__label-action {
        white-space: nowrap;
      }
    }
  }

  .slds-nav-vertical__item {
    .slds-nav-vertical__action {
      text-transform: uppercase;
      font-family: $font-family-heading;
      font-weight: 700;

      &.no-link:hover {
        background-color: transparent;
      }
    }

    &.slds-is-active {
      .slds-nav-vertical__action {
        background-color: transparent;
      }

      &::before {
        content: none;
      }
    }

    &.slds-theme_primary {
      .slds-nav-vertical__action {
        &:hover {
          background-color: $primary;
        }
      }
    }
  }

  .slds-dropdown {
    position: relative;
    padding-left: 1rem;
    float: none;
    border: none;
    box-shadow: none;
    display: none;
    height: 0;
    transition: height 0.1s linear;
    overflow-y: hidden;
    border-radius: 0;

    a {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.split-mobile-menu--sub-open {
      display: block;
      height: auto;
    }
  }

  .slds-icon_container {
    position: absolute;
    top: 0.25rem;
    right: 0;
  }

  .slds-icon-utility-close {
    padding: 0.5rem;
    top: 0;
    display: block;
    cursor: pointer;
  }

  .slds-icon-utility-chevronright {
    padding: 0.5rem;
    top: 0;
    display: block;
    cursor: pointer;
  }

  .slds-icon-utility-chevrondown {
    padding: 0.5rem;
    top: 0;
    display: none;
    cursor: pointer;
  }

  .expand-sub--open {
    .slds-icon-utility-chevronright {
      display: none;
    }

    .slds-icon-utility-chevrondown {
      display: block;
    }
  }
}

