.pager {
  @include list-reset();

  .button {
    @extend .slds-button;
    @extend .slds-button_outline-brand;

    font-family: $font-family-heading;
    font-weight: 700;
  }
}
