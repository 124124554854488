.countdown {
  display: flex;
  justify-content: center;
  font-size: 5rem;

  div.digit {
    display: flex;
    flex-direction: column;
    padding: 0.2em;
    @extend .slds-text-heading;
    font-size: 1em;
    line-height: 1;

    span {
      font-size: 0.2em;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  div.divider {
    border-left: 0.0125em solid $primary;
    height: 0.6em;
    margin-top: 0.425em;
  }
}
