/// Mixin - Transition
@mixin transition($transition-property, $transition-time, $method, $transition-delay: 0s) {
  -webkit-transition: $transition-property $transition-time $method $transition-delay;
  -moz-transition: $transition-property $transition-time $method $transition-delay;
  -ms-transition: $transition-property $transition-time $method $transition-delay;
  -o-transition: $transition-property $transition-time $method $transition-delay;
  transition: $transition-property $transition-time $method $transition-delay;
}

@mixin transition-reset() {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
}

.animate-fade {
  @include transition(opacity, 0.3s, ease-out);

  &:hover {
    opacity: 0;
  }
}

.animate-move {
  > .demo-shape {
    @include transition(all, 0.8s, ease-in-out);
  }
}
