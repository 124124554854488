// Create a mixin for paragraph styles that can be implemented
// in components with other classnames.
@mixin paragraph($margin: 0 0 1em) {
  margin: #{$margin};
}

@mixin body-copy {
  font-family: $font-family-text;
  font-size: 1rem;
  line-height: 1.25;

  @include large {
    line-height: 1.65;
  }
}

body {
  color: $gray-9;

  @include body-copy;
}

.paragraph {
  @include paragraph;
}

p {
  margin-bottom: 0.75rem;

  &.address {
    margin-bottom: 0;
  }
}

.slds-truncate_container_200 {
  max-width: 200%;
}

code {
  @extend .slds-text-font_monospace;
  @extend .slds-text-body_small;

  font-weight: bold;
}

.blockquote {
  font-style: italic;
  border-left: solid 3px $accessible;
  margin-left: 1em;
  padding-left: 1em;
}

.hr {
  border-style: solid;
  border-width: 1px 0 0;
  color: currentColor;
}

// TODO: BEM selectors should be applied to wysiwyg-created content

.text-long {
  p {
    @extend .paragraph;
  }

  blockquote {
    @extend .blockquote;
  }

  hr {
    @extend .hr;
  }
}

.slds-medium-text-align_center {
  @include medium {
    text-align: center;
  }
}

.slds-medium-text-align_right {
  @include medium {
    text-align: right;
  }
}

.slds-text-color_brand {
  color: $brand-primary;
}

.slds-text-color_accessible {
  color: $brand-accessible !important;
}