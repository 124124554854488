// Progress Indicator
.slds-progress__list {
  padding: 0;
}

.slds-progress.brand-primary {
  .slds-progress__list {
    .slds-progress__item {
      flex: 1 1 0;
      margin-top: 0;
      min-height: 1rem;
      text-align: center;
      position: relative;

      &:not(:first-child)::before {
        background: $primary;
        content: "";
        height: 2px;
        left: -50%;
        position: absolute;
        top: 9px;
        width: 100%;
      }

      .slds-progress__marker {
        display: block;
        top: 0.125rem;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        z-index: 1;
        color: $primary;

        &.slds-button_icon {
          top: -0.25rem;
        }
      }

      .slds-progress__item_content {
        color: $primary;
        padding: 20px 10px 0;
        text-decoration: none;
        width: 100%;
      }

      &.slds-is-active {
        .slds-progress__marker {
          border-color: $accessible;
        }

        & ~ .slds-progress__item::before {
          background: $gray-6;
        }

        & ~ .slds-progress__item .slds-progress__item_content {
          color: $gray-6;
        }
      }

      &.slds-has-error {
        .slds-progress__marker {
          border-color: transparent;
          color: $destructive;
        }
      }
    }
  }
}
