// Custom Community Menu

.slds-nav-horizontal {
  @include list-reset();
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: center;
}

.slds-nav-horizontal__label-action {
  text-transform: uppercase;
  margin: 0.5rem 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  text-decoration: none;
  font-weight: 700;
  color: $gray-6;
  line-height: 1.25;

  .slds-is-active &,
  &:hover {
    font-size: 1.25rem;
    line-height: 0.75;
  }

  .slds-is-active & {
    color: $brand-primary;
  }
}

.slds-nav-community {
  @extend .slds-context-bar;

  justify-content: center;
  border-bottom: 0;
  margin-bottom: 1rem;
  margin-left: 0;
  padding: 0;
}

.slds-nav-community__item {
  @extend .slds-context-bar__item;

  padding: 0 1rem;

  .slds-dropdown {
    width: 100%;
    list-style: none;
    margin-left: 0;
    font-size: 1.5rem;

    & .slds-dropdown__item a {
      text-decoration: none;
      color: $gray-6;
      text-align: center;

      .slds-is-active & {
        color: $brand-primary;
      }
    }
  }
}

.slds-nav-community__label-action {
  @extend .slds-context-bar__label-action;

  font-size: 1.5rem;
  text-decoration: none;
}

.slds-nav-community__icon-action {
  @extend .slds-context-bar__icon-action;

  font-size: 1.5rem;
}

.slds-nav-community__button {
  @extend .slds-context-bar__button;

  color: $accessible !important;
}