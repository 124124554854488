.p-floating-call-to-action {
    --p-floating-button-background: #fff;
    --p-floating-button-shadow: 3px 4px 7px rgba(0,0,0,.04);

    [data-theme="athletics"] & {
        --p-floating-button-background: none;
        --p-floating-button-shadow: none;
    }

    .sticky-to-bottom {
        position: relative;
    }

    @supports(position:sticky) {
        .sticky-to-bottom {
            position: sticky;
            bottom: 0;
        }
    }
    
    .flex-to-right {
        display: flex;
        justify-content: flex-end;
        padding: 2rem;

        .slds-button {
            background: var(--p-floating-button-background);
            box-shadow: var(--p-floating-button-shadow);
        }
    }
}