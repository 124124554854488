a {
  font-weight: 600;
  text-decoration: underline;
}

a.link {
  &.color-inherit {
    color: inherit !important;
  }
}

a.no-link {
  cursor: default;
}

a.slds-text-heading__link {
  color: inherit !important;
}

.givingday h3 > a {
  color: #ffae00;

  &:hover {
    color: #f4b838;
  }
}
