.slds-checkbutton {
  input[type=checkbox] {
    display: none;
  }

  .slds-checkbutton__label {
    @extend .slds-button;
    @extend .slds-button--outline-brand-primary;

    cursor: pointer;
  }

  input[type=checkbox]:checked + label {
    @extend .slds-button--brand-primary;
  }
}
