.main {
  margin-top: 1rem;

  @include large {
    margin-top: 12rem;

    .has-banner & {
      margin-top: 1rem;
    }
  }
}

#ServiceCommunityTemplate {
  [data-region-name="customHeader"] > .ui-widget > .forceCommunityRichText,
  [data-region-name="customRegion"] > .ui-widget > .forceCommunityRichText {
    max-height: 20vmax;
    overflow: hidden;
  }

  .cCenterPanel {
    height: auto;
    margin-top: 1rem;

    @include large {
      margin-top: 12rem;
    }

    .LTEFontevaPortalPageLayout {
      height: auto;
    }
  }

  [data-region-name="customHeader"] + .cCenterPanel,
  [data-region-name="customRegion"] + .cCenterPanel,
  .has-banner .cCenterPanel {
    margin-top: 1rem;
  }

}