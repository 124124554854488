.slds-icon_large, .slds-icon--large {
  width: var(--lwc-squareIconLargeBoundary,3rem);
  height: var(--lwc-squareIconLargeBoundary,3rem);
}

.slds-icon_x-large {
  width: 4rem;
  height: 4rem;
}

.slds-icon_xx-large {
  width: 6rem;
  height: 6rem;
}

.slds-icon_xxx-large {
  width: 10rem;
  height: 10rem;
}

.slds-icon.transparent {
  opacity: 0.15;
}

.slds-icon-text-accessible {
  fill: $accessible !important;
}