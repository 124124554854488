// .slds-container_responsive,
// .slds-container--responsive {
//   margin: 0 auto;
//   width: 100%;
//   max-width: 100vw;

//   @each $size in map-keys($breakpoints) {
//     // Store nested value from map
//     $breakpoint: map-get($breakpoints, $size);

//     @media (min-width: #{pem($breakpoint)}) {
//       max-width: $breakpoint;
//     }
//   }
// }

.slds-gutters {
  .slds-col {
    padding-left: 0;
    padding-right: 0;
  }

  > .slds-col {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
}

.slds-container_full-width,
.slds-container--full-width {
  @include breakout;
}

.slds-no-gutters .slds-col {
  padding-left: 0;
  padding-right: 0;
}

.slds-container_max-width,
.slds-container--max-width {
  max-width: $max-width;
}

.slds-m-top--none {
  margin-top: 0;
}

.slds-small-m-top--none {
  @include small {
    margin-top: 0;
  }
}

.slds-small-m-top--auto {
  @include small {
    margin-top: auto;
  }
}

.slds-large-align_absolute-center {
  @include large {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: auto;
  }
}

.slds-medium-align_absolute-center {
  @include medium {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: auto;
  }
}

.slds-small-align_absolute-center {
  @include small {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: auto;
  }
}

.slds-align_left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
  -ms-flex-line-pack: left;
  align-content: left;
  -webkit-box-align: left;
  -ms-flex-align: left;
  align-items: left;
  margin: auto;
}

.slds-large-align_left {
  @include large {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    -ms-flex-line-pack: left;
    align-content: left;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    margin: auto;
  }
}

.slds-medium-align_left {
  @include medium {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    -ms-flex-line-pack: left;
    align-content: left;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    margin: auto;
  }
}

.slds-small-align_left {
  @include small {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    -ms-flex-line-pack: left;
    align-content: left;
    -webkit-box-align: left;
    -ms-flex-align: left;
    align-items: left;
    margin: auto;
  }
}

.slds-col > .text-long {
  width: 100%;
}

.slds-grid--align-spreadspace, 
.slds-grid_align-spreadspace {
  @extend .slds-grid_align-spread;

  @include large {
    justify-content: space-around;
  }
}

.slds-grid_align-start {
  align-items: start;
}