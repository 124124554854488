.pl {
  .patternLink {
    color: $gray-11;
  }

  .visually-hidden {
    position: absolute !important;
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    height: 1px;
    width: 1px;
    word-wrap: normal;
  }

  .sg-pattern {
    clear: left;
  }

  #icons {
    display: flex;
    flex-wrap: wrap;

    & > div {
      border: 1px solid #eee;
      margin-right: 1em;
      padding: 1em;
      text-align: center;
    }

    .icon {
      height: 4rem;
      width: 4rem;
    }
  }
}
