//  Empty
.slds-global-header_container {
  position: fixed;

  @include large {
    position: absolute;

    .scrolled & {
      position: fixed;
    }

    .toolbar-fixed &,
    .toolbar-vertical & {
      top: 39px;
    }

    .toolbar-fixed.toolbar-horizontal.toolbar-tray-open & {
      top: 79px;
    }
  }

  .toolbar-fixed &,
  .toolbar-vertical & {
    top: 39px;
  }

  .toolbar-fixed.toolbar-horizontal.toolbar-tray-open & {
    top: 79px;
  }

  .slds-global-header {
    width: 100%;
    background: rgba(255, 255, 255, 1);
    box-shadow: none;
    padding: 0;
    height: auto;

    @include large {
      height: 8rem;

      .scrolled & {
        height: auto;

        .slds-hide_scrolled {
          display: none;
        }
      }
    }

    .slds-context-bar {
      padding-top: 0;
      padding-bottom: 0;
      font-family: $font-family-heading;
      font-weight: 700;
    }

    .slds-list_horizontal a.slds-list_horizontal__label-action {
      text-transform: uppercase;
      color: $gray-7;
      font-family: $font-family-heading;
      font-weight: 700;
      margin: 0.25rem;
      padding: 0.25rem 0.5rem;
      font-size: 0.9rem;
      line-height: 1.6em;

      &:active,
      &:hover {
        color: inherit;
        color: $gray-11;
      }
    }
  }
}

#organisms-site-header {
  margin-bottom: 12rem;
}
