// Custom media

.slds-avatar_x-large {
  @extend .slds-avatar_large;

  width: 4rem;
  height: 4rem;
}

.slds-avatar_xx-large {
  @extend .slds-avatar_large;

  width: 6rem;
  height: 6rem;
}

.text-long {
  img {
    padding: 1rem;
  }

  figure {
    padding: 1rem;
    img {
      padding: 0;
    }
  }
}
