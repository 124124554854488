$breadcrumb-active: #bbb;

.slds-breadcrumb {
  @include list-reset;

  margin-top: 1rem;
  margin-bottom: 3rem !important;
}

.breadcrumb__item,
.breadcrumb__link,
.breadcrumb__link:link,
.breadcrumb__link:visited,
.breadcrumb__link:focus {
  color: $gray-8;
  text-decoration: none;
}

.breadcrumb__link:hover {
  color: $gray-9;
}

.breadcrumb__item {
  display: inline-block;
  font-size: 0.75rem;

  &::after {
    content: "/";
  }

  &:last-child {
    color: $breadcrumb-active;

    &::after {
      content: "";
    }
  }
}

.breadcrumb_link:active {
  color: $breadcrumb-active;
}

.slds-breadcrumb .slds-list__item > span,
.slds-breadcrumb__item > span {
  display: block;
  padding: 0 0.5rem;
}
