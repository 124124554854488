.p-section {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 2rem 0;

  @include medium {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  &.use-margin {
    padding: 0;
  }

  &.cover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.inline {
    margin-top: 0;
    margin-bottom: 0;

    @include medium {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
