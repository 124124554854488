//

.slds-button {
  &.slds-button--xx-large,
  &.slds-button_xx-large {
    font-size: 2rem;
    line-height: 1.5;
  }
}

.slds-button--icon {
  @extend .slds-button_icon;
}

.slds-button--weak
.slds-button_weak {
  color: $gray-9;

  &:hover {
    color: $gray-10;
  }
}

.slds-button--outline-brand,
.slds-button_outline-brand {
  @extend .slds-button_outline-brand;

  background: rgba(0, 0, 0, 0);

  &.transparent {
    opacity: 0.5;
  }
}

.slds-button--outline-brand-primary,
.slds-button_outline-brand-primary {
  @extend .slds-button_outline-brand;

  background: rgba(0, 0, 0, 0);
  border-color: $brand-primary;
  color: $brand-primary;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
    border-color: $brand-primary-active;
    color: $brand-primary-active;
  }

  &.transparent {
    opacity: 0.5;
  }
}

.slds-button--brand-primary,
.slds-button_brand-primary {
  @extend .slds-button_brand;

  background-color: $brand-primary;
  border-color: $brand-primary;

  &:hover {
    background-color: $brand-primary-active;
    border-color: $brand-primary-active;
  }
}

.slds-button--icon {
  @extend .slds-button_icon;
}

.slds-button {
  @extend .slds-button;

  text-transform: uppercase;
  font-size: 1rem;
  font-family: $font-family-heading;
  font-weight: 700;
}

.slds-checkbox_add-button {
  line-height: 1;
}

.slds-button--icon-x-small,
.slds-button_icon-x-small {
  @extend .slds-button_icon-x-small;

  font-size: 0.75rem;
}

.width-full {
  width: 100%;
}