.p-instagram-tiles {
  .slds-col {
    &:nth-child(1) { order: 1; }
    &:nth-child(2) { order: 2; }
    &:nth-child(3) { order: 4; }
    &:nth-child(4) { order: 3; }
    &:nth-child(5) { order: 5; }
    &:nth-child(6) { order: 6; }
    &:nth-child(7) { order: 8; }
    &:nth-child(8) { order: 7; }

    @include large {
      &:nth-child(1) { order: 1; }
      &:nth-child(2) { order: 2; }
      &:nth-child(3) { order: 3; }
      &:nth-child(4) { order: 4; }
      &:nth-child(5) { order: 6; }
      &:nth-child(6) { order: 5; }
      &:nth-child(7) { order: 8; }
      &:nth-child(8) { order: 7; }
    }
  }
}
