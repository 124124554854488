// Custom Inline Menu

.slds-list_horizontal {
  @include list-reset();
}

.slds-list_two-column {
  @include list-reset();

  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.slds-large-list_two-column {
  @include large {
    @include list-reset();

    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

.slds-medium-list_two-column {
  @include medium {
    @include list-reset();

    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

.slds-small-list_two-column {
  @include small {
    @include list-reset();

    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
}

.slds-list_horizontal__label-action {
  text-transform: uppercase;
  margin: 0.5rem 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.9rem;
  line-height: 1.6em;
}
