.card-with-image .slds-card {
  overflow: hidden;
}

.slds-card .rounded img {
  border-radius: 50%;
}

.slds-card .slds-card__header-collapsed {
  margin: 0;
  padding: 0;
}
