$table-gray: #f8f8f8;

.table {
  border: 1px solid $gray-4;
  border-radius: 4px;
  border-spacing: 0;
  background-color: $table-gray;
  width: 100%;
}

.table__heading-cell {
  border: none;
  border-left: 1px solid $gray-4;
  color: $gray-12;
  font-weight: 700;
  padding: 1.2em;
  text-align: left;

  &:first-child {
    border: none;
  }
}

.table__row {
  &:nth-child(odd) {
    background-color: $gray-1;
  }

  // Non-BEM but always contained
  &:last-child {
    td:first-child {
      border-radius: 0 0 0 5px;
    }

    td:last-child {
      border-radius: 0 0 5px;
    }
  }
}

.table__cell {
  border: 1px solid $gray-4;
  border-left: none;
  border-bottom: none;
  padding: 1em;

  &:last-child {
    border-right: none;
  }
}

.table__footer-cell,
.table__footer-cell:first-child {
  border-left: none;
  border-top: 1px solid $gray-4;
}

// TODO: BEM selectors should be applied to wysiwyg-created content

.text-long {
  table {
    @extend .table;
  }

  th {
    @extend .table__heading-cell;
  }

  td {
    @extend .table__cell;
  }

  tbody tr {
    @extend .table__row;
  }

  tfoot th {
    @extend .table__footer-cell;
  }
}
