// Empty
.footer {
  width: 100%;
  background-color: $gray-1;
  color: $accessible;
  font-family: $font-family-text;
  font-size: $font-size-text-small;
  border-top: 1px $gray-6 solid;

  ul {
    @include list-reset();
  }

  .slds-container--x-large {
    margin: 0 auto;
  }

  p {
    color: $primary;
    margin-bottom: 0;
    text-align: center;
  }

  .footer-menu {
    color: $gray-6;

    a {
      color: $gray-6;
      text-transform: none;

      &:focus,
      &:hover {
        color: $gray-6;
        text-transform: none;
      }
    }
  }

  a {
    svg {
      color: $gray-6 !important;
    }

    color: $primary;
    text-decoration: none;
    font-weight: 400;

    &:focus,
    &:hover {
      svg {
        color: $gray-6 !important;
      }

      color: $primary-active;
    }
  }

  svg.logo {
    height: 4rem;
    width: 4rem;
  }

  svg.logo-large {
    height: 6rem;
    width: 6rem;
  }
}
